<template>
  <div>
    <tab-component>
      <!-- FILTERS -->
      <template>

        <v-multiselect v-model="selectedSchoolTerm"
                       :options="schoolTerms"
                       :multiple="false"
                       @select="(value) => setFilterProperty({schoolTerms: value.id})"
                       @remove="(value) => clearFilterProperty({schoolTerms: value.id})"
                       label="id"
                       trackBy="id"
                       placeholder="Vælg semester"
                       class="light"
                       :close-on-select="false"
                       :show-labels="true"
                       select-label="Vælg"
                       selected-label="Valgt"
                       deselect-label="Fjern"
                       :searchable="false">

          <!-- Component label when not open -->
          <template slot="selection"
                    slot-scope="{ values, isOpen }">
            <span class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen">
              {{ values.length === 1 ? values[0].id : `${values.length} semestre` }}
            </span>
          </template>

          <!-- Don't show selection as tags -->
          <template slot="tag">
            <div></div>
          </template>
        </v-multiselect>

        <v-multiselect v-model="selectedDepartment"
                       :options="departments"
                       :multiple="true"
                       @select="(value) => setFilterProperty({departments: value.id})"
                       @remove="(value) => clearFilterProperty({departments: value.id})"
                       label="name"
                       trackBy="id"
                       class="light"
                       placeholder="Vælg afdeling"
                       :close-on-select="false"
                       :show-labels="true"
                       :searchable="false"
                       select-label="Vælg"
                       selected-label="Valgt"
                       deselect-label="Fjern">

          <!-- Component label when not open -->
          <template slot="selection"
                    slot-scope="{ values, isOpen }">
            <span class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen">
              {{ values.length === 1 ? values[0].name : `${values.length} afdelinger` }}
            </span>
          </template>

          <!-- Don't show selection as tags -->
          <template slot="tag">
            <div></div>
          </template>
        </v-multiselect>

        <v-multiselect v-model="selectedSubject"
                       :options="schoolSubjects"
                       :multiple="true"
                       @select="(value) => setFilterProperty({schoolSubjects: value.id})"
                       @remove="(value) => clearFilterProperty({schoolSubjects: value.id})"
                       label="title"
                       trackBy="id"
                       class="light"
                       placeholder="Vælg fag"
                       :close-on-select="false"
                       :show-labels="true"
                       select-label="Vælg"
                       selected-label="Valgt"
                       deselect-label="Fjern"
                       :searchable="false">

          <!-- Component label when not open -->
          <template slot="selection"
                    slot-scope="{ values, isOpen }">
            <span class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen">
              {{ values.length === 1 ? values[0].title : `${values.length} fag valgt` }}
            </span>
          </template>

          <!-- Don't show selection as tags -->
          <template slot="tag">
            <div></div>
          </template>
        </v-multiselect>

      </template>

      <!-- ACTIONS -->
      <template #taboverride>
        <router-link class="button is-dark"
                     to="/teachers/new">Tilføj ny underviser</router-link>
      </template>
    </tab-component>

    <section>
      <b-table :data="teachers"
               :loading="isLoading"
               hoverable
               striped
               @click="openTeacher">
        <!-- It pains me to not be able to use an a -->
        <template #empty>
          <p>Ingen undervisere matcher filtre</p>
        </template>

        <b-table-column field="initials"
                        label="Initialer"
                        v-slot="props">
          {{ props.row.initials }}
        </b-table-column>

        <b-table-column field="first-name"
                        label="Fornavn"
                        v-slot="props">
          {{ props.row.firstName }}
        </b-table-column>

        <b-table-column field="last-name"
                        label="Efternavn"
                        v-slot="props">
          {{ props.row.lastName }}
        </b-table-column>

        <b-table-column field="icon">
          <p class="has-text-right">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </p>
        </b-table-column>
      </b-table>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "teachers-overview-view",
  data: () => ({
    selectedSchoolTerm: null,
    selectedDepartment: null,
    selectedSubject: null,

  }),
  beforeMount() {
    this.resetFilters();
    // this.applyGlobalSchoolTermSelection();
  },
  async mounted() {
    this.updatePageTitle('Undervisere');
    await this.loadTeachers();
  },
  computed: {
    // ...mapGetters(['globalSchoolTermSelection']),
    ...mapGetters("teachers", ["teachers", "isLoading"]),
    ...mapGetters("departments", ["departments"]),
    ...mapGetters("schoolTermStore", ["schoolTerms"]),
    ...mapGetters("schoolSubjectsStore", ["schoolSubjects"]),
    ...mapGetters({
      isLoadingTeachers: "teachers/isLoading",
    }),

  },
  methods: {
    ...mapActions(['updatePageTitle']),
    ...mapActions("teachers", ["loadTeachers", "setFilterProperty", "clearFilterProperty", "resetFilters"]),
    openTeacher(teacher) {
      this.$router.push(`/teachers/${teacher.id}`);
    },
    // applyGlobalSchoolTermSelection() {
    //   const globalSchoolTerm = this.schoolTerms.find(q => q.id === this.globalSchoolTermSelection);
    //   if (globalSchoolTerm) {
    //     this.setFilterProperty({schoolTerms: globalSchoolTerm.id});
    //     this.selectedSchoolTerm = globalSchoolTerm;
    //   }
    // }

  }
};
</script>