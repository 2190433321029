var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('tab-component',{scopedSlots:_vm._u([{key:"taboverride",fn:function(){return [_c('router-link',{staticClass:"button is-dark",attrs:{"to":"/teachers/new"}},[_vm._v("Tilføj ny underviser")])]},proxy:true}])},[[_c('v-multiselect',{staticClass:"light",attrs:{"options":_vm.schoolTerms,"multiple":false,"label":"id","trackBy":"id","placeholder":"Vælg semester","close-on-select":false,"show-labels":true,"select-label":"Vælg","selected-label":"Valgt","deselect-label":"Fjern","searchable":false},on:{"select":function (value) { return _vm.setFilterProperty({schoolTerms: value.id}); },"remove":function (value) { return _vm.clearFilterProperty({schoolTerms: value.id}); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length === 1 ? values[0].id : ((values.length) + " semestre"))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedSchoolTerm),callback:function ($$v) {_vm.selectedSchoolTerm=$$v},expression:"selectedSchoolTerm"}},[_c('template',{slot:"tag"},[_c('div')])],2),_c('v-multiselect',{staticClass:"light",attrs:{"options":_vm.departments,"multiple":true,"label":"name","trackBy":"id","placeholder":"Vælg afdeling","close-on-select":false,"show-labels":true,"searchable":false,"select-label":"Vælg","selected-label":"Valgt","deselect-label":"Fjern"},on:{"select":function (value) { return _vm.setFilterProperty({departments: value.id}); },"remove":function (value) { return _vm.clearFilterProperty({departments: value.id}); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length === 1 ? values[0].name : ((values.length) + " afdelinger"))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedDepartment),callback:function ($$v) {_vm.selectedDepartment=$$v},expression:"selectedDepartment"}},[_c('template',{slot:"tag"},[_c('div')])],2),_c('v-multiselect',{staticClass:"light",attrs:{"options":_vm.schoolSubjects,"multiple":true,"label":"title","trackBy":"id","placeholder":"Vælg fag","close-on-select":false,"show-labels":true,"select-label":"Vælg","selected-label":"Valgt","deselect-label":"Fjern","searchable":false},on:{"select":function (value) { return _vm.setFilterProperty({schoolSubjects: value.id}); },"remove":function (value) { return _vm.clearFilterProperty({schoolSubjects: value.id}); }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length === 1 ? values[0].title : ((values.length) + " fag valgt"))+" ")]):_vm._e()]}}]),model:{value:(_vm.selectedSubject),callback:function ($$v) {_vm.selectedSubject=$$v},expression:"selectedSubject"}},[_c('template',{slot:"tag"},[_c('div')])],2)]],2),_c('section',[_c('b-table',{attrs:{"data":_vm.teachers,"loading":_vm.isLoading,"hoverable":"","striped":""},on:{"click":_vm.openTeacher},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',[_vm._v("Ingen undervisere matcher filtre")])]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"initials","label":"Initialer"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.initials)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"first-name","label":"Fornavn"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.firstName)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"last-name","label":"Efternavn"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.lastName)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"icon"}},[_c('p',{staticClass:"has-text-right"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }